import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class NotFound extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Videóim " />
        <Hero title="Hamarosan!" />
      </Layout>
    )
  }
}

export default NotFound
